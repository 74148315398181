import {Controller} from '@hotwired/stimulus';
import Swal from 'sweetalert2';
import user from "../js/user";
import {consola as l} from "consola/basic";

export default class extends Controller {

    static values = {
        urlLogin: String,
        urlLoginGoogle: String
    }

    static targets = ['loginIcon'];

    async openModal() {

        if (await user.isLogged()) {
            this._showLoggedModal()
        } else {
            await this._showLoginModal();
        }
    }

    _showLoggedModal() {
        Swal.fire({
            title: 'Ti vuoi disconnettere?',
            html:
                `
            <div>
              <a href="/edit-user" class="block my-4" >Modifica profilo utente</a>
            </div>
`,
            showCancelButton: true,
            confirmButtonText: 'Disconnetti',
            cancelButtonText: `No rimango loggato`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await user.deleteUser()
                this.loginIconTarget.classList.remove('logged');
                this._logoutFromGoogle()
                await this._fireToast('success', 'Alla prossima 😀');
            }
        })
    }

    async _googleLogin(token) {

        const response = await fetch(this.urlLoginGoogleValue, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: token,
            })
        });

        if (!response.ok) {
            await this._fireToast('warning', response.headers.get('X-message'))

            this._logoutFromGoogle()
            this._googleButtonVisibility(true)
            return false;
        }

        await this._setUserLogged(await response.json(), 'Loggato via Google con successo!')
    }

    async _showLoginModal() {

        Swal.fire({
            title: 'Accedi o registrati',
            html:
                `
            <div id="login-google" ><i class="cm-ico cm-loader"></i></div>
            <div id="google-loader" hidden><i class="cm-ico cm-loader"></i></div>
            <input id="swal-email" class="swal2-input" type="email" placeholder="Email">
            <input id="swal-password" class="swal2-input" type="password" placeholder="Password">
            <div>
              <a href="/recupera-password" class="block my-4" >Password dimenticata?</a>
            </div>
            <div>
              <a href="/registrati" class="block my-4" >Crea un nuovo account</a>
            </div>
`,
            showCancelButton: true,
            confirmButtonText: 'Accedi',
            cancelButtonText: 'Cancella',
            showLoaderOnConfirm: true,
            didOpen: () => {

                window._google_onSuccess = (googleUser) => {

                    window.googleUser = googleUser;

                    console.log('Logged in as: ', googleUser.getBasicProfile().getName());
                    console.log('Logged in email: ', googleUser.getBasicProfile().getEmail());
                    console.log('ToKen: ', googleUser.getAuthResponse().id_token);

                    this._googleButtonVisibility(false)
                    this._googleLogin(googleUser.getAuthResponse().id_token);
                }

                window._google_onFailure = async (error) => {

                    this._logoutFromGoogle()

                    l.error(error);
                    await this._fireToast('error', 'Errore di autenticazione google')
                    this._googleButtonVisibility(true)
                }

                window._google_renderButton = () => {
                    gapi.signin2.render('login-google', {
                        'scope': 'profile email',
                        'width': 240,
                        'height': 50,
                        'longtitle': true,
                        'theme': 'dark',
                        'onsuccess': _google_onSuccess,
                        'onfailure': _google_onFailure
                    });
                }

                this._loadGoogleLibrary()
            },
            preConfirm: async (login) => {

                try {
                    const response = await fetch(this.urlLoginValue, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            email: document.getElementById('swal-email').value,
                            password: document.getElementById('swal-password').value
                        })
                    });

                    if (!response.ok) {
                        await this._fireToast('warning', await response.json())
                        return false;
                    }

                    return await response.json();

                } catch (error) {
                    await this._fireToast('error', error)
                    return false;
                }

            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {

                await this._setUserLogged(result.value, 'Loggato con successo!')
            }
        })
    }

    _logoutFromGoogle() {

        if (typeof gapi !== 'undefined') {
            gapi.auth2.getAuthInstance().signOut()
            l.info('Disconnessione da google');
        } else {
            l.info('Libreria gogle mancante');
            this._loadGoogleLibrary(() => {
                l.info('Disconnessione da google dopo caricamento libreria');
                gapi.auth2.getAuthInstance().signOut()
            });

        }
    }


    _loadGoogleLibrary(fn = null) {
        const head = document.getElementsByTagName("head")[0];
        const script = document.createElement("script");

        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.src = '//apis.google.com/js/platform.js?onload=_google_renderButton';

        if (typeof fn === "function") {
            script.addEventListener('load', fn);
        }

        head.appendChild(script);
    }

    _googleButtonVisibility(visibility) {

        const googleButton = document.getElementById('google-loader');
        const googleSpinnerButton = document.getElementById('login-google');

        if (visibility === true) {
            googleButton.removeAttribute("hidden")
            googleSpinnerButton.setAttribute("hidden", "hidden")
        } else {
            googleButton.setAttribute("hidden", "hidden")
            googleSpinnerButton.removeAttribute("hidden")
        }

    }

    async _setUserLogged(userObject, message) {
        await user.deleteUser();
        await user.setUser(userObject);
        this.loginIconTarget.classList.add('logged');
        await this._fireToast('success', message)
    }

    async _fireToast(icon, title) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true
        })

        await Toast.fire({
            icon: icon,
            title: title
        })
    }
}
